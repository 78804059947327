import { Configuration, BalanceApi, RequestBalanceOnWalletResponse } from '../sdk';
import { urls } from '../config';
import { GET_BALANCES } from './constants';
import Cookies from 'universal-cookie';
import type { RawAxiosRequestConfig } from 'axios';

const setBalances = (data: RequestBalanceOnWalletResponse[], walletId: string) => ({ type: GET_BALANCES, data, walletId });

const config = new Configuration({
  basePath: urls.baseUrl,
});

const getBaseOptions = (): RawAxiosRequestConfig => {
  const cookies = new Cookies();
  return {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('jwt_token')}`,
    },
  }
}

export const getBalances = (signal: any, id: any) => async (dispatch: any) => {
  try {
    const res = await new BalanceApi(config).v1DashboardWalletsBalancesIdPost(id.walletId, { signal, ...getBaseOptions() });
    const output = res.data.data.reduce((acc, bal) => {
      acc.push({
        asset: bal.asset.toLowerCase(),
        network: bal.network,
        balance: bal.balance,
      });
      return acc;
    }, []);
    await dispatch(setBalances(output, id));
    return output;
  } catch (e) {
    return { error: `An error occurred while fetching balances: ${e}` };
  }
};