import { urls } from '../config';
import axiosRequest from './axios';

/**
 * @description - Get all notifications for a user identified with their Cognito subject (sub).
 * @param {string} subject - The Cognito subject (sub) for the logged-in user.
 */
export function getNotifications(subject) {
  return axiosRequest(`${urls.notificationsUrl}/user/${subject}`)
    .then(res => {
      return res;
    })
    .catch(_ => {
      return [];
    });
}

/**
 * @description - Mark all notifications as seen.
 * @param {string} sub - The Cognito subject (sub) for the logged-in user.
 */
export function markNotificationsAsSeen(sub) {
  return axiosRequest(`${urls.notificationsUrl}/mark-all`, {
    method: 'POST',
    data: { sub },
  })
    .then(res => {
      return res;
    })
    .catch(_ => {
      return [];
    });
}

/**
 * @description - Get count of all unread notifications for a user identified with their Cognito subject (sub).
 * @param {string} subject - The Cognito subject (sub) for the logged-in user.
 */
export function unreadCount(subject) {
  return axiosRequest(`${urls.notificationsUrl}/user/${subject}/count`)
    .then(res => {
      return res;
    })
    .catch(_ => {
      return [];
    });
}
